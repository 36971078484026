<template>
    <base-page>
        <!-- <titlu-pagina Titlu="Dashboard" /> -->

        <el-row v-if="show_piese" :gutter="12" style="margin-top:10px;">   
            <el-col style="margin-bottom:5px;background-color: lightgreen; text-align: center;">Piese</el-col>                                   
            <el-col :span="8" v-for="item in Results.Piese" :key="item.Id">
                <el-button border class="full-width" style="height:180px;" @click="selecteaza(item)">
                    {{item.Nume}} <br><br> {{item.Pret |format_money}} lei
                </el-button>
            </el-col>                                                                                
        </el-row>

        <el-row v-if="show_manopera" :gutter="12" style="margin-top:10px;">   
            <el-col style="margin-bottom:5px;background-color: lightgreen; text-align: center;">Manopera</el-col>                            
            <el-col :span="8" v-for="item in Results.Manopera" :key="item.Id">
                <el-button border class="full-width" style="height:180px;" @click="selecteaza(item)">
                    {{item.Nume}} <br><br> {{item.Pret |format_money}} lei
                </el-button>
            </el-col>                                                                                
        </el-row>

        <el-row v-if="show_kit" :gutter="12" style="margin-top:10px;">
            <el-col style="margin-bottom:5px;background-color: lightgreen; text-align: center;">Kit</el-col>                             
            <el-col :span="8" v-for="item in Results.Kit" :key="item.Id">
                <el-button border class="full-width" style="height:180px;" @click="selecteaza(item)">
                    {{item.Nume}} <br><br> {{item.Pret |format_money}} lei
                </el-button>
            </el-col>                                                                                
        </el-row>
            
</base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "servicii_ultrarapide",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: Object,
            base_url: '',
            show_piese: false,
            show_manopera: false,
            show_kit: false,
        }
    },
    methods: {
        async get_info(){                        
            var response = await this.post("dashboard_mecanic/get_produse_ultrarapide" );
            this.Results = response.Results
            if(this.Results.Piese.length > 0)       this.show_piese     = true
            if(this.Results.Manopera.length > 0)    this.show_manopera  = true
            if(this.Results.Kit.length > 0)         this.show_kit       = true                       
        },

        async selecteaza(item){
            ///this.$set(item, 'selected', !item.selected)    
            this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                type: 'warning'
                }).then(async() => {                
                    var response = await this.post("dashboard_mecanic/set_servicii_ultrarapide", {id_produs: item.Id});
                    this.$message({type: 'info', message: response.mesaj});
                     }).catch(() => {
                        this.$message({type: 'info', message: 'Anulat !'});                                                                 
                        });
        } 

    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
